.hrep-grid {


  &.e-grid {
    border: 0;
    font-family: Lato, sans-serif;

    .e-gridheader {
      border: 0;

      .e-table {
        border-spacing: 5px 0;
      }
    }

    .e-headercelldiv {
      color: $hrep-border;
      font-weight: normal;
    }

    .e-gridcontent {
      .e-table {
        border-spacing: 5px 10px;
      }

      tr {
        &:first-child {
          td {
            border-color: #ddd;
          }
        }

        td {
          &:last-child {
            border: 0;
          }
        }
      }
    }

    &.e-bothlines {
      .e-headercell {
        border: 0;
      }

      .e-rowcell {
        border-width: 1px;

        &.e-templatecellX {
          border: 0;
          padding: 0;
        }
      }
    }

    &:not(.e-row-responsive) {
      .e-gridcontent tr {
        &.e-row {
          &:first-child {
            .e-rowcell:not(:last-child) {
              border-top: 1px solid #dddddd;
            }
          }
        }
      }
    }
  }

  &.first-column-button {
    .e-templatecell{
      border: 0 !important;
      padding: 0;
    }
  }
}

.hrep-fill-icon {
  &.e-badge.e-badge-circle {
    width: 16px;
    height: 16px;
    position: relative;
  }

  span {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
