.e-btn,
.e-css.e-btn {
  font-family: Lato, sans-serif;
  &.e-flat.e-primary,
  &.e-primary {
    @include hrep-primary-button();
  }

  &.e-small {
    min-height: auto;
  }
}

.hrep-btn-round {
  text-align: center;

  .e-btn {
    position: relative;
    margin-bottom: 10px;

    &.e-round {
      border-color: $hrep-border;
      color: $hrep-border;
      width: 42px;
      height: 42px;

      .e-btn-icon {
        font-size: 1.4375rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: $hrep-orange;
        color: $hrep-white;
      }
    }
  }

  p {
    font-size: 0.6rem;
    line-height: 1.83;
    color: $hrep-border;
  }

  strong {
    font-size: 12px;
    line-height: 1.2;
  }

  &.btn-large {
    .e-btn {
      border-width: 2px;

      &.e-round {
        width: 90px;
        height: 90px;

        .e-btn-icon {
          font-size: 2.5rem;
        }
      }
    }

    p {
      font-size: .875rem;
      line-height: 1.04;
    }
  }
}

.hrep-btn-light {
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    background-color: $hrep-orange;
    color: $hrep-white;
    border-color: $hrep-orange;
  }
}

.hrep-icon-btn {
  background-color: transparent;
  border: 0;

  &.e-icon-btn {
    padding: 0 8px 0 0;

    &:last-child {
      padding: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }
}

.e-btn-group {
  button {
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
  .e-btn {
    &:active,
    &:focus {
      &.e-primary {
        background-color: $hrep-orange;
        border-color: $hrep-orange;
      }
    }
  }
}
